<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header
          id="modalTitle"
          class="modal-header"
          :class="{
            articlein: direction === 'IN',
            articleout: direction === 'OUT'
          }">
          <slot name="header"> This is the default tile! {{ force }} </slot>
        </header>

        <section id="modalDescription" class="modal-body">
          <label class="modal-label">Artikelnummer</label>
          <div class="modal-text">{{ article.article_number }}</div>

          <label class="modal-label">Benämning</label>
          <div class="modal-text">{{ article.article_name }}</div>

          <label class="modal-label">I lager</label>
          <div class="modal-text">{{ article.article_amount }} st</div>
          <br />
          <label class="modal-label">Antal</label>
          <input ref="articleamount" v-model.number="amount" class="pg-input" type="number" @focus="$event.target.select()" @keyup.enter="updateArticle()" />
          <div v-if="errorMessage" class="errorMessageText">
            {{ errorMessage }}
          </div>
          <!-- <slot name="body">
            This is the default body!
          </slot>-->
        </section>

        <footer class="modal-footer">
          <slot name="footer"> </slot>
          <button type="button" class="btn-primary btn-action" aria-label="Close modal" @click="updateArticle">OK</button>
          <img src="../assets/history-solid.svg" class="svg-icon" @click.prevent="toogleHistory" title="Visa historik" />

          <button type="button" class="btn-danger btn-action" aria-label="Close modal" @click="close">Avbryt</button>
        </footer>

        <section v-if="showHistory">
          <hr />
          <h6 class="pg-center pg-bold">Historik</h6>
          <table class="content-table-history content-table-striped">
            <thead>
              <tr>
                <th>Datum</th>
                <th class="pg-right"><slot name="history-header"></slot></th>
              </tr>
            </thead>
            <tbody v-if="filtredHistory.length > 0">
              <template v-for="myHistory in filtredHistory">
                <template>
                  <tr :key="myHistory.warehouse_history_id">
                    <td>{{ myHistory.create_date }}</td>

                    <td class="pg-right">{{ myHistory.article_amount }}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  </transition>
</template>
<script>
import { isNumberValid } from '@/services/validationHelper';

export default {
  name: 'PGWarehouseModal',
  props: {
    direction: {
      type: String,
      default: 'DEFAULT'
    },
    article: {
      type: Object
    },
    force: {
      type: Number
    },
    history: {
      type: Boolean
    }
  },
  data() {
    return {
      errorMessage: '',
      amount: 0,
      showHistory: this.history
    };
  },
  computed: {
    filtredHistory() {
      const historyDirection = this.direction;
      if (this.article.history) {
        return this.article.history.filter((history) => {
          return history.direction.match(historyDirection);
        });
      } else {
        return [];
      }
    }
  },
  async mounted() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.$emit('close');
      }
    });

    this.$refs.articleamount.focus();
  },
  updated() {
    this.$refs.articleamount.focus();
  },
  methods: {
    // ...mapActions({
    //   fetchWarehouseArticleWithHistory: 'articles/fetchWarehouseArticleWithHistory',
    //   updateArticleWarehouse: 'articles-utils/updateArticleWarehouse'
    // }),
    close() {
      this.amount = 0;
      this.errorMessage = '';
      this.$emit('close');
    },
    toogleHistory() {
      this.showHistory = !this.showHistory;
      this.$store.commit('setShowWarehouseHistory', this.showHistory);
    },
    updateArticle() {
      if (isNumberValid(this.amount)) {
        let newWarehouseAmount = 0;
        if (this.direction === 'IN') {
          newWarehouseAmount = this.article.article_amount + this.amount;
        } else {
          newWarehouseAmount = this.article.article_amount - this.amount;
          if (newWarehouseAmount < 0) {
            newWarehouseAmount = 0;
          }
        }
        let data = {
          article_id: this.article.article_id,
          article_amount: newWarehouseAmount,
          article_amount_for_history: this.amount,
          warehouse_id: this.article.warehouse_id,
          warehouses_id: this.article.warehouses_id,
          storage_location_id: this.article.storage_location_id,
          storage_location: this.article.storage_location,
          direction: this.direction
        };
        this.amount = 0;
        this.errorMessage = '';
        this.$emit('update', data);
      } else {
        this.errorMessage = 'Värdet måste vara numeriskt';
      }
    }
  }
};
</script>

<style lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  //display: flex;
  //  justify-content: center;
  //  align-items: center;
  width: 100%;
  height: 100%;
}

.modal2 {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
.modal {
  width: 400px;
  padding: 20px;
  margin: 80px auto;
  background: white;
  border-radius: 10px;

  h1 {
    color: #03cfb4;
    border: none;
    padding: 0;
  }

  p {
    font-style: normal;
  }
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #fff;
  font-size: 1.3rem;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
}

.modal-header.articlein {
  background: #3db64a;
}
.modal-header.articleout {
  background: #ff7300;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: row;
  justify-content: space-between;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-text {
  margin: 0px 0;
  display: block;
  text-align: left;
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
}
.modal-label {
  margin: 5px 0;
  display: block;
  text-align: left;
  line-height: 1.5;
  font-size: 0.7rem;
  font-weight: 600;
  margin-bottom: 0;
}
.btn-action {
  min-width: 100px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>
