<template>
  <div id="warehouse" class="grid">
    <div class="pg-header">
      <h2>Lager</h2>
    </div>
    <div id="alertBox" role="alert">
      <transition-group name="fade">
        <div v-show="showSuccessAlertBox" key="ok" class="alert alert-success">
          <strong>{{ message }}</strong>
        </div>
        <div v-show="showDangerAlertBox" key="nok" class="alert alert-danger">
          <strong>{{ message }}</strong>
        </div>
      </transition-group>
    </div>
    <div class="pg-table">
      <div v-if="errorMessage === '' && !loading">
        <div class="pg-table-toolbar">
          <div class="pg-search-input">
            <input v-model="search" type="text" class="searchInput" placeholder="Sök" />
          </div>
          <div class="pg-table-toolbar-action-buttons">
            <div v-if="isWorker || isSeller || isAdmin">
              <button class="btn-primary" onmousedown="event.preventDefault()" @click="reload">Uppdatera</button>
            </div>
            <div v-if="isWorker || isSeller || isAdmin">
              <button class="btn-primary" @click="addArticle">Lägg till</button>
            </div>
          </div>
        </div>
        <div>
          <table id="tblArticles" class="content-table">
            <thead>
              <tr>
                <th></th>
                <th>Artikelnummer</th>
                <th>Art.nr. leverantör</th>
                <th>Benämning</th>
                <th>Notering</th>
                <th>Lagerplats</th>
                <th>I lager</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="!loading && articles.length > 0">
              <tr v-for="article in filtredArticles" :key="article.article_id">
                <td>
                  <div v-if="article.article_amount <= article.alarm_limit">
                    <img src="../../assets/exclamation-triangle-solid.svg" class="svg-icon-no-pointer svg-icon-alert" />
                  </div>
                </td>
                <td>{{ article.article_number }}</td>
                <td>{{ article.article_number_supplier }}</td>
                <td>{{ article.article_name }}</td>
                <td>{{ article.article_description }}</td>
                <td>{{ article.storage_location_name }}</td>
                <td
                  :class="{
                    alarm_limit: article.article_amount <= article.alarm_limit
                  }">
                  {{ article.article_amount }}
                </td>
                <td @click="articleIn(article)">
                  <img src="../../assets/truck-solid.svg" class="svg-icon svg-icon-edit" title="Inleverera" />
                </td>
                <td @click="articleOut(article)">
                  <img src="../../assets/box-open-solid.svg" class="svg-icon svg-icon-edit" title="Lagerplock" />
                </td>
                <td @click="editArticle(article.article_id)">
                  <img src="../../assets/edit-regular.svg" class="svg-icon svg-icon-edit" title="Editera artikel" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="loading && errorMessage === ''" class="pg-center">
        <Spinner></Spinner>
      </div>
      <div v-if="errorMessage !== ''" class="pg-center">
        <p class="errorMessageText">{{ errorMessage }}</p>
      </div>
    </div>
    <warehouse-modal
      v-show="isModalVisible"
      :direction="direction"
      :article="myWarehouseArticle"
      :force="forceUpdateModelCounter"
      :history="showWarehouseHistory"
      @close="closeModal"
      @update="updateArticle">
      <template #header>
        <template v-if="direction == 'IN'"> Inleverera </template>
        <template v-if="direction == 'OUT'"> Lagerplock </template>
      </template>
      <template #history-header>
        <template v-if="direction == 'IN'"> Inlevererat </template>
        <template v-if="direction == 'OUT'"> Lagerplockad </template>
      </template>
    </warehouse-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from '@/components/utils/Spinner';
import PGWarehouseModal from '@/components/PG-WarehouseModal.vue';

export default {
  name: 'Warehouse',
  components: {
    Spinner,
    'warehouse-modal': PGWarehouseModal
  },
  props: {
    addUpdateOK: {
      type: Boolean,
      default: false
    },
    hasAddUpdate: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      showModal: false,
      isModalVisible: false,
      forceUpdateModelCounter: 0,
      articles: [],
      myWarehouseArticle: {
        article_id: 0,
        article_number: '',
        article_number_supplier: '',
        article_name: '',
        article_description: '',
        alarm_limit: 0,
        article_amount: 0,
        warehouse_id: 0,
        warehouses_id: 0,
        storage_location_id: 1,
        storage_location: '',
        history: []
      },
      direction: '',
      search: '',
      message: '',
      showSuccessAlertBox: false,
      showDangerAlertBox: false
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    isWorker: function () {
      return this.$store.getters.isWorker;
    },
    isSeller: function () {
      return this.$store.getters.isSeller;
    },
    showWarehouseHistory: function () {
      return this.$store.getters.showWarehouseHistory;
    },
    filtredArticles() {
      const trimmedSearch = this.search.toLowerCase().trim();
      return this.articles.filter((article) => {
        return (
          article.article_number.toLowerCase().match(trimmedSearch) ||
          article.article_name.toLowerCase().match(trimmedSearch) ||
          article.article_description.toLocaleString().toLowerCase().match(trimmedSearch) ||
          article.article_amount.toLocaleString().toLowerCase().match(trimmedSearch) ||
          article.storage_location_name.toLocaleString().toLowerCase().match(trimmedSearch)
        );
      });
    }
  },
  created() {
    if (this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Artiklen är nu sparad';
      }
      if (this.action == 'DELETE') {
        this.message = 'Artiklen är nu raderad';
      }
      setTimeout(() => (this.showSuccessAlertBox = true), 0);
      setTimeout(() => (this.showSuccessAlertBox = false), 3000);
    }
    if (!this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Artiklen kunde inte sparas';
      }
      if (this.action == 'DELETE') {
        this.message = 'Artiklen kunde inte raderas';
      }
      setTimeout(() => (this.showDangerAlertBox = true), 0);
      setTimeout(() => (this.showDangerAlertBox = false), 3000);
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchMyWarehouseArticles();
    this.loading = false;
  },
  updated() {},
  beforeUpdate() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      fetchWarehouseArticles: 'articles/fetchWarehouseArticles',
      fetchWarehouseArticleWithHistory: 'articles/fetchWarehouseArticleWithHistory',
      updateArticleWarehouse: 'articles-utils/updateArticleWarehouse'
    }),
    closeModal() {
      this.showModal = false;
      this.isModalVisible = false;
    },
    editArticle: function (articleId) {
      this.$router.replace({
        name: 'Article',
        params: {
          articleId: articleId,
          warehousesId: 1,
          originView: 'Warehouse'
        }
      });
    },
    articleIn: async function (article) {
      const data = {
        article_id: article.article_id,
        warehouses_id: article.warehouses_id
      };
      this.myWarehouseArticle = await this.fetchWarehouseArticleWithHistory(data);
      this.forceUpdateModelCounter = this.forceUpdateModelCounter + 1;
      this.direction = 'IN';
      this.isModalVisible = true;
    },
    articleOut: async function (article) {
      const data = {
        article_id: article.article_id,
        warehouses_id: article.warehouses_id
      };
      this.myWarehouseArticle = await this.fetchWarehouseArticleWithHistory(data);
      this.forceUpdateModelCounter = this.forceUpdateModelCounter + 1;
      this.direction = 'OUT';
      this.isModalVisible = true;
    },
    updateArticle: function (newarticle) {
      this.closeModal();
      this.myWarehouseArticle = newarticle;
      this.updateMyArticleWarehouse();
    },
    addArticle() {
      this.$router.replace({
        name: 'Article',
        params: {
          articleId: 0,
          warehousesId: 1,
          originView: 'Warehouse'
        }
      });
    },
    async reload() {
      await this.fetchMyWarehouseArticles();
    },
    async fetchMyWarehouseArticles() {
      this.errorMessage = '';
      try {
        this.articles = [];
        const data = {
          warehouses_id: 1
        };
        this.articles = await this.fetchWarehouseArticles(data);
      } catch (error) {
        //console.log('fetchMyWarehouseArticles ERROR');
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              // console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      }
    },
    async updateMyArticleWarehouse() {
      this.errorMessage = '';
      try {
        await this.updateArticleWarehouse(this.myWarehouseArticle);
        await this.fetchMyWarehouseArticles();
      } catch (error) {
        //console.log('fetchMyWarehouseArticles ERROR');
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              // console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pg-table {
  grid-column: 3/11;
}
#alertBox {
  grid-column: 3/11;
}
@media (max-width: 1690px) {
  .pg-table {
    grid-column: 2/12;
  }
  #alertBox {
    grid-column: 2/12;
  }
}
@media (max-width: 1270px) {
  .pg-table {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  #alertBox {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
</style>
